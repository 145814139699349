import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaClock,
  FaList,
  FaUsers,
  FaPuzzlePiece,
  FaSchool,
  FaPlus,
  FaSlidersH,
  FaLaptop,
  FaCreditCard,
} from "react-icons/fa";
import Container from "../components/container";
import * as styles from "./index.module.css";
import Tile from "../components/Tile";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import heroImage2 from "../images/system-crm-dla-firmy-handlowej-i-uslugowej.jpg";
import Columns from "../components/columns";
import ResponsiveEmbed from "react-responsive-embed";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const SpecjalistaMarketingu = ({ data }) => {
  return (
    <FunctionPageLayout title="Specjalista ds. marketingu" headline="Dołącz do zespołu">
      <SEO
        title="Specjalista ds. marketingu - oferta pracy"
        description="Producent oprogramowania dla firm ✅ Oferta pracy • Specjalista ds. marketingu • Skontaktuj się z nami"
      />
      <br />
      <Container>
        <h2>Kim jesteśmy?</h2>
        <br />
        <p>
          Jesteśmy{" "}
          <strong> producentem i dostawcą oprogramowania dla biznesu</strong>{" "}
          działającym od 2002 roku. Pracujemy zarówno dla dużych,
          rozpoznawalnych firm, jak i dla mniejszych – dynamicznie rozwijających
          się przedsiębiorstw. Wspieramy klientów biznesowymi narzędzami, które
          umożliwiają realizację strategii, zapewniając tym samym lepsze i
          przyjemniejsze jutro. Nasz zespół składa się z około 40 specjalistów,
          których łączy wspólna pasja — technologie informacyjne.
        </p>
        <br />
        <br />
        <h2> Wartości, które wyznajemy:</h2>
        <br />
        <p>
          <ul>
            <li>
              {" "}
              stosujemy dobre praktyki zarządzania, a za główny miernik wartości
              uznajemy osiągane rezultaty,{" "}
            </li>
            <li> stawiamy na ciągłe samodoskonalenie, rozwój i edukację, </li>
            <li> do osiągania celów wykorzystujemy wiedzę i doświadczenie, </li>
            <li>
              {" "}
              traktujemy klientów i pracowników jako partnerów, z którymi
              wspólnie budujemy przyszłość,{" "}
            </li>
            <li> w codziennej pracy cenimy zaangażowanie i pasję, </li>
            <li>
              {" "}
              stawiamy na otwartą komunikację – jeśli coś nie działa, mówimy o
              tym głośno,{" "}
            </li>
            <li>
              {" "}
              nie jesteśmy korporacją – u nas wszyscy się znają i współpracują,{" "}
            </li>
            <li>
              {" "}
              stawiamy na pracę zespołową, bo sukces w naszej branży zależy od
              zaangażowania wielu osób,{" "}
            </li>
            <li>
              {" "}
              i… last but not least – bardzo lubimy technologie informatyczne!{" "}
            </li>
          </ul>
        </p>
      </Container>
      <h2
        style={{
          paddingBottom: 30,
          paddingTop: 20,
        }}
      >
        Tworzymy zgrany zespół
      </h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              To, co motywuje nas do codziennego działania to współpraca. <br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy
              naszych klientów, świętujemy nasze sukcesy, świetnie się przy tym
              wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                Przecież w dobrej atmosferze obowiązki stają się przyjemnością,
                prawda?
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      <h2
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          textAlign: "center",
        }}
      >
        {" "}
        Dlaczego warto z nami pracować?{" "}
      </h2>

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaClock size="60px" />}
            title="Stabilna firma o ugruntowanej pozycji na rynku"
          />
          <BenefitBlock
            icon={<FaUsers size="60px" />}
            title="50-osobowy zgrany zespół"
          />
          <BenefitBlock
            icon={<FaPuzzlePiece size="60px" />}
            title="Różnorodność projektów i technologii"
          />
        </BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaSchool size="60px" />}
            title="Dzielimy się wiedzą i doświadczeniem"
          />
          <BenefitBlock
            icon={<FaSlidersH size="60px" />}
            title="Jesteśmy elastyczni i do każdego podchodzimy indywidualnie"
          />
          <BenefitBlock
            icon={<FaPlus size="60px" />}
            title="Oferujemy dodatkowe benefity"
          />
        </BenefitBlockWrapper>
      </div>

      <center>
        <h3>Specjalista ds. marketingu</h3>
        <p>Praca stacjonarna na pełen etat. Po wdrożeniu możliwa praca w modelu hybrydowym.</p>
      </center>
      <br />
      <p>
        <strong>
          Twoim celem będzie współudział w tworzeniu i realizacji strategii
          marketingowej dla marki Questy oraz marek produktowych.{" "}
        </strong>
      </p>
      <br />
      <p>
        <strong>Czym będziesz się zajmować?</strong>
      </p>
      <p>
        <ul>
          <li>
            <b>
              uczestniczyć w burzach mózgów, abyśmy wspólnie pracowali nad
              najlepszymi koncepcjami w obszarze rozwoju marketingu,
            </b>
          </li>
          <li>
            <b>obserwować trendy i kreować własne pomysły,</b>
          </li>
          <li>
            współtworzyć plany marketingowe (np. tworzenia i publikacji materiałów edukacyjnych),
          </li>
          <li>współuczestniczyć w zarządzaniu kampaniami Google Ads i Facebook Ads dla produktów Questy, </li>
          <li>tworzyć treści edukacyjne w różnych formatach (np. artykuły na blogu, vlogi, prezentacje, posty w mediach społecznościowych, kampanie e-mail).</li>
        </ul>
      </p>
      <br />
      <p>
        <strong>Jakiej osoby szukamy?</strong>
      </p>
      <p>
        <ul>
          <li>
            <b>doświadczonej (minimum rok na podobnym stanowisku),</b>
          </li>
          <li>
           płynnie mówiącej w języku polskim,
          </li>
          <li>pomysłowej i kreatywnej,</li>
          <li>zafascynowanej marketingiem, reklamą,</li>
          <li>pozytywnie nastawionej do poznawania nowych rzeczy,</li>
          <li>
            skupionej na zadaniach,
          </li>
          <li>komunikatywnej,</li>
          <li>otwartej na opinie innych w celu poszerzania własnych horyzontów.</li>
        </ul>
      </p>
      <br />
      <p>
        <b>Świetnie pasujesz do tej roli, jeśli:</b>
      </p>
      <br />
      <p>
        <ul>
          <li>potrafisz zarządzać kampaniami Google Ads, Facebook Ads,</li>
          <li>znasz i umiejętnie wykorzystujesz takie narzędzia jak: Google Analytics, GTM (podstawy), Google Search Console, Hotjar, Senuto,</li>
          <li>lubisz dzielić się swoimi pomysłami i chcesz realizować nowe rzeczy,</li>
          <li>znasz zasady języka polskiego i lubisz pisać treści różnego typu,</li>
          <li>lubisz technologie,</li>
          <li>jesteś studentem zaocznym lub absolwentem kierunków takich jak marketing, reklama, biznes lub pokrewnych.</li>
        </ul>
      </p>
    <br />
      <p>
        <center>
          <b>Spełniasz większość z podanych przez nas wymagań? Aplikuj!</b>
        </center>
      </p>
      <br />
      <p>
        {" "}
        <strong> Co zyskujesz dołączając do naszego zespołu? </strong>{" "}
      </p>
      <p>
        <ul>
          <li> Szansę na poznanie świata IT od kuchni.</li>
          <li> Poznanie i zrozumienie procesów marketingowo-sprzedażowych.</li>
          <li> Szansę na długoterminową współpracę.</li>
          <li> Elastyczny grafik.</li>
          <li>
            Ciekawą pracę w dynamicznej firmie w zespole ludzi nastawionych na
            przyszłość i rozwój.
          </li>
          <li>
            <b>Szansę na realizowanie własnych pomysłów</b>, bo w naszej firmie
            stawiamy na innowacyjność wypływającą z zespołu.
          </li>
          <li>
            Wsparcie doświadczonego (i fajnego) zespołu, zainteresowanego grą do
            jednej bramki.
          </li>
          <li>Możliwość dalszego rozwoju w kierunku marketingu.</li>
          <li>Dofinansowanie do karty Multisport.</li>
          <li>Premie za wysokie zaangażowanie i wkład w rozwój firmy.</li>
          <li>Zewnętrzne szkolenia poszerzające wiedzę z obszaru, którym się zajmujesz.</li>
          <li>Przyjazną atmosferę i spotkania integracyjne.</li>
          <li>
            Pracę w biurze w centrum Poznania (3 min pieszo od Dworca
            Zachodniego) oraz szansę na pracę hybrydową (częściowo zdalną) w
            przypadku dalszej współpracy.
          </li>
          <li> Pyszną kawę każdego dnia 🙂</li>
        </ul>
      </p>

      <Container>
        <h2
          style={{
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          Przekonaliśmy Cię?
          <br />
          Zobacz, jak do nas dołączyć.{" "}
        </h2>
        <br />
        <GatsbyImage
          image={data.rekrutacja.childImageSharp.gatsbyImageData}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            marginTop: 30,
          }}
          alt="rekrutacja"
        />

        <br />
        <br />
      </Container>
      <h2> Czekamy na Ciebie! </h2>
      <br />
      <br />
      <center>
        <p>
          Wyślij CV z dopiskiem <strong>„Specjalista ds. marketingu”</strong> oraz zgodą na
          przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>

        <p>
          {" "}
          Informujemy, że skontaktujemy się osobiście tylko z wybranymi
          kandydatami.{" "}
        </p>
      </center>
      <br />
      <br />
      <br />

      <p>Prosimy o zawarcie w CV klauzuli: </p>

      <p>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z siedzibą w Poznaniu zgodnie z ustawą z dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default SpecjalistaMarketingu;
